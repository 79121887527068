@font-face {
  font-family: 'Switzer';
  src: url(./css/fonts/Switzer/Switzer-Regular.ttf);
}
body {
  font-family: 'Switzer', sans-serif;
}
.tab-count-active{
  background: rgba(248, 176, 43, 1);
  padding: 0.5px 5px;
  border-radius: 5px;
  color: #212529;
  font-size: 12px;
}
.tab-count-inactive{
  background: rgba(111, 113, 116, 1);
  padding: 0.5px 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidemenu {
  background-color: black;
  height: 100vh;
}

.sub {
  color: white;
  font-size: 14px;
  margin-top: 60px;
}

.position-relative {
  position: relative;
}

.logo-wrapper {
  position: absolute;
  bottom: 0px;
}

.title-text {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}

.poweredby {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.return-to-login-text {
  color: black;
}
.timer-text1 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(22, 23, 29, 1);
}

.timer-text2 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.Dashboard-items {
  list-style: none;
}

.Dashboard-items li a {
  margin-top: 20px;
  justify-items: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  color: rgba(138, 139, 142, 1);
  text-decoration: none;
}

.Dashboard-items li span {
  margin-left: 10px;
}

.Dashboard-items .active {
  color: white;
}

.Dashboard-items .inactive {
  color: rgba(138, 139, 142, 1);
}

.main-scrollable {
  height: 75vh;
  overflow: scroll;
}

.fCap {
  text-transform: capitalize;
}

.modal {
  background-color: rgba(0, 0, 0, 0.4) !important;
  display: block !important;
}

/* .modall {
  background-color: rgba(0, 0, 0, 0) !important; 
  display: block !important;
  z-index: 999;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
} */

.modall {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.modal-dialog {
  max-width: 600px;
  width: 100%;
  padding: 20px;
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border-radius: 20px;
}

.error-message {
  color: red;
  font-size: 0.875rem;
}


.filePickerContainer {
  overflow: hidden;
  padding: relative;
}

.filePicker {
  width: 100px;
  height: 80px;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0 !important;
  cursor: pointer;
}

.btn-inactive {
  width: 103px;
  height: 44px;
  padding: 12px, 24px, 12px, 24px;
  border-radius: 8px;
  border: 1px;
  background-color: #93939373;
}
.btn-yellow {
  width: 132px;
  height: 44px;
  padding: 12px, 24px, 12px, 24px;
  border-radius: 8px;
  background-color: #f8b02b;
}

.error {
  color: red;
  font-size: 12px;
}

.emailContainer {
  gap: 4px;
  display: flex;
}

.emailItem {
  display: flex;
  height: 28px;
  padding: 2.8px 10px;
  border-radius: 4px;
  border: 0.6px;
  border: 0.6px solid #a0a3a6;
  background-color: white;
  text-align: center;
  vertical-align: middle;
  margin: 5px 2px;
  font-size: 12px;
}

.noborder {
  border: none;
  outline: none;
}

.input-wrapper-b {
  border: solid 1px #ddd;
  background-color: red;
}

.btn-close-b {
  font-size: 8px;
  margin-left: 5px;
  margin-top: 3.5px;
}

.form-control input {
  width: 100%;
}

.pl-c .nav-link {
  color: #282c34;
  padding: 0px 10px;
}

.pl-c .nav-link-active {
  color: orange;
}

.dropdown-wrp {
  position: relative;
}

.dropdown-cnt {
  position: absolute;
  bottom: -100px;
  right: 0px;
  background-color: white;
  z-index: 9999;
}

.dropdown-cnt ul {
  list-style: none;
  padding: 5px 10px;
  padding-bottom: 0px;
  background-color: white;
}

.dropdown-cnt ul li {
  display: flex;
  align-items: center;
  min-width: 140px;
  font-size: 14px;
  padding: 10px 0px;
  cursor: pointer;
}

.dropdown-cnt ul li span {
  padding-left: 5px;
}

.btn-close {
  font-size: 14px;
}

.dx {
  color: #6f7174;
  font-size: small;
}

.dxx {
  font-size: medium;
}

.table-title {
  font-family: 'Switzer', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.chart-title {
  font-family: 'Switzer', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.012em;
}

.chart-date {
  font-family: 'Switzer', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
}

.chart-tabs-container {
  width: 288px;
  height: 40px;
  border-radius: 8px;
  background: #f7f7f7;
  box-shadow: 0px 1px 10px 0px #00000014 inset;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 3px;
}

.chart-tabs-active {
  background-color: white;
}

.chart-tabs-container .col-4 {
  font-family: 'Switzer', sans-serif;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.c-box-checkin {
  width: 16px;
  height: 16px;
  background-color: #3b6fe9;
}
.cb-txt {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
}

.c-box-checkout {
  width: 16px;
  height: 16px;
  background-color: #f8b02b;
}

.selected-tab {
  border-bottom: solid 2px #f8b02b;
  border-radius: 0px;
}

.orange-text {
  color: #f8b02b;
}

.calendar-card {
  z-index: 9999999 !important;
  display: flex;
  justify-content: center;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  right: -10px;
  top: 45px;
  z-index: 9999;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
}

.react-calendar {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.filterButton {
  max-width: none !important;
}

.mx-1 {
  display: inline-block !important;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
}
.dashboard-card {
  border-radius: 12px;
  border:solid 1px rgba(247, 247, 247, 1) ;
  padding-top: 8px;
  padding-top: 20px;
}
