.bx {
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #444;
  gap: 4px;
  display: flex;
  align-items: center;
  position: relative;
}

.bx a {
  color: black;
  text-decoration: none;
}

.bx .caret {
  position: absolute;
  right: 10px;
}

.bx .tx {
  font-size: 14px;
  padding-left: 10px;
}

.dx-card {
  width: '90%';
  height: 114px;
}

.black-card {
  border-radius: 12px;
  background-color: black;
  color: white;
}

.yellow-card {
  border: none;
  border-radius: 12px;
  background-color: rgba(250, 212, 97, 1);
  color: black;
}

.gray-card {
  border: none;
  border-radius: 12px;
  background-color: rgba(243, 243, 244, 1);
  color: black;
}

.dx-card .t1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.dx-card .t2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.dx-card .circle {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 20px;
}

.dx-card .gt {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.44);
  width: 60px;
  height: 20px;
  padding: 2px, 3px, 2px, 3px;
  border-radius: 4px;
  font-size: 12px;
  color: limegreen;
  font-weight: bold;
}

.text-2 {
  display: inline-block;
  font-size: 12px;
  padding-top: 10px;
}

.searchbar-container {
  position: relative;
  display: flex;
  align-items: center;
}

.close-btn {
  position: relative;
  right: 20px;
  cursor: pointer;
}

.searchbar-icon {
  position: absolute;
  left: 20px;
}

.searchbar-container > input {
  padding-left: 30px;
}

.avatar {
  background-color: #fdf2d0;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  text-align: center;
}

.nameText {
  font-family: 'Switzer';
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

/* Adjust the .bx component for smaller screens */
@media (max-width: 768px) {
  .bx {
    height: auto;
    padding: 8px;
    font-size: 12px;
  }

  .bx .tx {
    font-size: 12px;
    padding-left: 8px;
  }

  .bx .caret {
    right: 5px;
  }

  .dx-card {
    width: 100%;
    height: auto;
    /* Adjust height for smaller screens */
  }

  .dx-card .t1,
  .dx-card .t2 {
    font-size: 14px;
    /* Smaller font size for smaller screens */
  }

  .dx-card .circle {
    width: 40px;
    height: 40px;
    top: 10px;
    right: 10px;
  }

  .dx-card .gt {
    width: auto;
    height: auto;
    font-size: 10px;
    padding: 1px 2px;
  }

  .text-2 {
    font-size: 10px;
    padding-top: 5px;
  }

  .searchbar-container {
    flex-direction: column;
  }

  .searchbar-container > input {
    width: 100%;
    padding-left: 10px;
  }

  .searchbar-icon {
    left: 10px;
  }

  .close-btn {
    right: 10px;
  }

  .avatar {
    width: 60px;
    height: 60px;
  }

  .nameText {
    font-size: 18px;
  }
}

.divider {
  height: 1px;
  background-color: #ddd;
  margin: 15px 0;
}

.transport-management-container {
  margin-top: 4px;
  width: 560px;
  height: 435px;
}

.transport-top {
  background-color: #fff3f3;
  color: #fd632c;
  border-radius: 20px;
  padding: 15px;
  gap: 10px;
  border: 1px solid transparent;
  display: flex;
}

.form-select {
  border-radius: 15px;
  height: 50px;
  margin-bottom: 28px;
  width: 430px;
}

.transport-check-content {
  display: flex;
  padding: 0 2px;
  color: #4d5154;
  gap: 12px;
  /* width: 500px; */
}

.transport-box {
  width: 205px;
  height: 47px;
  padding: 8px 12px;
  gap: 0px;
  border-radius: 8px;
  border: 1px solid #a0a3a6;
  justify-content: space-between;
}

.bold-label {
  font-weight: bold;
}

/* Default color when the switch is off */
.form-check-input {
  background-color: rgb(255, 255, 255);
  border-color: #D0D0D2;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

/* Change color when the switch is on */
.form-check-input:checked {
  background-color: #34c759;
  border-color: #34c759;
}

/* Add a green outline when checked */
.form-check-input:checked:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 0, 0.25);
}

.custom-style {
  width: 130px;
  height: 52px;
  padding: 10px 12px;
  border-radius: 25px;
  border: 1px solid #a0a3a6;
}

.setting-profile {
    display: flex;
    gap: 64px;
}
.business-logo {
  display: flex;
  padding-top: 9;
  gap: 20px;
  align-items: center;
}

.business-logo-button {
  border: 1px solid #000000;
  background-color: #fff;
  padding: 10px;
  height: 44px;
  border-radius: 30px;
  color: var(--text-primary, #212529);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4000000059604645px;
}

.input-narrow {
  width: 400px;
}

.custom-input {
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.business-name {
  margin: 15px;
}

.business-place {
  padding: 0 3px 0 0;
  width: 200px;
}

.file-input {
  display: none;
  /* Hide the default file input */
}

.business-file {
  padding: 0 9px;
  margin: 0;
  width: 430px;
  cursor: pointer;
}

.business-upload-row {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15ox 20px;
  border-radius: 5px;
}

.business-upload-content {
  display: flex;
}

.custom-width-input {
  width: 60%;
}

.notification-wrapper {
  position: relative;
  display: inline-block;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;
}

.uploaded-logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.radio-group {
  height: 30px;
  display: flex;
  border: 1px solid #bcbcbd;
  border-radius: 5px;
  width: 31.5%;
}

.radio-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}

.radio-box:first-child .box {
  border-left: none;
}

.radio-box input[type='radio'] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.radio-box .box {
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 14px;
  border-left: 1px solid #bcbcbd;
}

.radio-box input[type='radio']:checked + .box {
  background-color: #f8b02b;
  border-radius: 5px;
}

.radio-box .box::before {
  content: attr(data-text);
}

.body-text {
  max-width: 230px;
  color: #817f7f;
}
